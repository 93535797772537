.userservice-modal {
  .btn {
    padding: 0;
    background-color: #E10210;
    text-transform: initial;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    height: 30px;
    width: 80.83px;
    border-radius: 1px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  }
  .save{
    background-color: #E10210!important;
    color: #FFFFFF;
    font-size: 14px;
  }
  .upload {
    height: 65px;
    width: 148.83px;
    background-color: #E10210!important;
    color: #FFFFFF;
    font-size: 14px;
  }
  .cancel{
    background-color: #F4F4F4!important;
    color: #686868;
    font-size: 14px;
    margin-right: 15px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    &:hover {
      color: #686868!important;
    }
  }
  .modal-content{
    background: linear-gradient(90deg, #E10210 25%, #ffffff 25%);
  }
  .modal-title {
    width: 24%;
    height: 50px;
    padding: 0;
  }
  .service-title {
    color: #ffffff;
    font-family: "Myriad Pro Bold";
    font-size: 27px;
    letter-spacing: 0;
    line-height: 32px;
  }
  .file-name {
    font-family: "Myriad Pro Bold";
    font-size: 27px;
    letter-spacing: 0;
    line-height: 32px;
  }
  .modal-header {
    border-bottom: none;
    .btn-primary {
      height: 30px;
      padding: 0 15px 0 15px;
      width: 130px;
    }
  }
  .modal-footer {
    border-top: none;
  }
}
