.privacy-padding {
	padding-left: 5rem;

}

.privacy-title-text {
  margin: 15px 0 45px 0;
  height: 24px;
  font-size: 24px;
  font-weight: 1000;
  letter-spacing: 0;
}
