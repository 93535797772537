.modaltemplate-scss {
  .modal-content {
    box-sizing: border-box;
    border: 1px solid #E10210;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
    padding: 0 0 5px 0;
    .modal-header {
      border-bottom: none !important;
    }
    .modal-footer {
      border-top: none !important;
      
    }
    .btn {
      padding: 2px 10px;
      background-color: #E10210;
      text-transform: initial;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      height: auto;
      width: 130px;
      border-radius: 1px;
      box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    }
    .save{
      background-color: #E10210!important;
      color: #FFFFFF;
      font-size: 14px;
    }
    .delete-category-btn {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
    }
    .cancel{
      background-color: #F4F4F4!important;
      color: #686868;
      font-size: 14px;
    }

    .trash-btn {
      position: absolute;
      top: 30px;
      right: 35px;
      background-color: #FFFFFF !important;
      height: 40px;
      width: auto;
      border-radius: 50%;
      img{
        width: 17px;
      }
    }

    .form-outline .form-control ~.form-notch {
      display: none;
    }
    .form-outline .form-control {
      height: 30px;
      border-radius: 2px;
      background-color: #F4F4F4;
    }
    .form-outline .form-control ~ .form-label {
      padding-left: 10px;
    }
    .form-control {
      color: #686868!important;
      font-family: "Myriad Pro"!important;
      font-size: 16px!important;
      letter-spacing: 0!important;
      line-height: 24px!important;
    }

  }
}
.modal-title {
  height: 32px;
  color: #303030;
  font-family: "Myriad Pro Bold";
  font-size: 27px;
  letter-spacing: 0;
  line-height: 32px;
}
.section-submneu-text {
  height: 34px;
  color: #303030;
  font-family: "Myriad Pro Bold";
  font-size: 19px;
  letter-spacing: 0;
  line-height: 34px;
}
.video-background {
  background-color: #EAEAEA;
}
.ckeditor-padding {
  padding-top: 15px;
}
