.bodywrapper {
	min-height: 100vh;
	padding-top: 0px;
	//background-image: url('../../media/images/Group 0.png');
	//background-size: 100% 100%;
	//background-repeat: no-repeat;
	//background-position: top center;
}




@media (max-width: 575.98px) {
	.bodywrapper {
		padding-top: 0;
	}

}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bodywrapper {
		padding-top: 0;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.bodywrapper {
		padding-top: 0;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.bodywrapper {
		padding-top: 0;
	}
}

@media (min-width: 1200px) and (max-width: 1499.98px) {

}
